import React, { useMemo, CSSProperties } from 'react';
import { useGetList } from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import { subDays, startOfDay } from 'date-fns';

import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';
import OrderChart from './OrderChart';

import { Order, ProjectTotal } from '../types';
import NewPersons from './NewPersons';
import NewEmployees from './NewEmployees';
import TotalProjects from './TotalProjects';
import data from '../segments/data';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Order[];
}

interface State {
    nbNewOrders?: number;
    pendingOrders?: Order[];
    recentOrders?: Order[];
    revenue?: string;
    totalProjects?:string;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    /* const { data: projects } = useGetList<ProjectTotal>('projects', {
        filter: {  },
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 1000 },
    });
    const aggregation = useMemo<State>(() => {
        if (!projects) return {};
        
        return {
            totalProjects: projects.length.toString()
        };
    }, [projects]);
    const { totalProjects } = aggregation; */
    //const projects = data.length.toString();
    
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            <div style={styles.flex}>
               
            </div>
            <div style={styles.singleCol}>
                
            </div>
            <div style={styles.singleCol}>
                
            </div>
        </div>
    ) : (
        <>
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                <div style={styles.flex}>
                    
                        
                    </div>
                    
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                    
                    
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
/*
<TotalProjects value='10' />
<NewPersons/>
<Spacer />
<NewEmployees/>
 */