import { Theme, useMediaQuery } from "@mui/material";
import { CreateButton, Datagrid, DateField, FunctionField, List, Loading, NullableBooleanInput, SearchInput, ShowButton, TextField, TopToolbar, useAuthenticated, useAuthState, useTranslate, WrapperField } from "react-admin";
import ColoredLevel from "./ColoredLevel";
import LoggingAppListAside from './LoggingAppListAside';
import LoggingAppShow from "./LoggingShow";
import { Pagination } from 'react-admin';

const url = `${process.env.REACT_APP_API_URL || 'https://logs.walk.in'}`;
const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const LoggingAppList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const translate = useTranslate();

    const positions: any = [];
    return (
        <List  aside={<LoggingAppListAside data={positions}/>} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }} pagination={<PostPagination />}>
            <Datagrid rowClick="expand" expand={<LoggingAppShow />}>
                <TextField source="id" />
                <TextField source="corellationId" />
                <TextField source="env" />
                <ColoredLevel sortable={false} label="Level" source="level"/>
                <FunctionField label="Date" render={(record: any) => { 
                    try{
                        const date2 = new Date(parseInt(record.date));
                        return `${date2.toString().split('GMT')[0]}`;
                    } catch (e){
                        return 'error';
                    }
                    } } />
                <TextField source="message" />
            </Datagrid>
        </List>
    );

}
const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>

    
    </TopToolbar>
);

export default LoggingAppList;