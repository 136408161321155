import { AuthProvider, useRedirect } from 'react-admin';
import { useLocation } from 'react-router';


const url = `${process.env.REACT_APP_API_URL}`;

const authProvider: AuthProvider = {
    
    
    login: ({ username, password }) => {
        /*localStorage.setItem('username', username);
        // accept all username/password combinations*/
        
        const request = new Request(`${url}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ email:username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials:'include',
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({id, role, username})=>{
                localStorage.setItem('role', role);
                localStorage.setItem('userId', id);
                localStorage.setItem('username', username);
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    logout: () => {
        //localStorage.removeItem('username');
        //return Promise.resolve();
        
        const request = new Request(`${url}/auth/logout`, {
            method: 'POST',
            //body: JSON.stringify({ email:username, password }),
            //headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials:'include',
            redirect: 'follow'
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                
                return Promise.resolve();
            })
            .then(()=>{
                localStorage.removeItem('role');
                localStorage.removeItem('userId');
                localStorage.removeItem('username');
            })
            .catch(() => {
                throw new Error('Network error')
            });
            
    },
    checkError: ({status}) => {
        
        if (status === 401 ) {
            return Promise.reject({ redirectTo: '/login' });
          }
          return Promise.resolve();
    },
    checkAuth: () =>{
        const request = new Request(`${url}/auth/authenticate`, {
            method: 'GET',
            credentials:'include',
            redirect: 'follow'
        });
        return fetch(request)
            .then(response => {
                if(response.status === 401){
                    return Promise.reject({ redirectTo: '/login' });
                }
                return Promise.resolve();
            })
            
            .catch((e) => {
                console.log('ggggg');
                return Promise.reject({ redirectTo: '/login' });
            });
        },
    getPermissions: () => {
        //const role = localStorage.getItem('role');
        const role = 'admin';
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
        const id = localStorage.getItem('userId');
        console.log('id'+id)
        if(!id)  return Promise.reject({ redirectTo: '/login' });

        return Promise.resolve({
            id: localStorage.getItem('userId') || 'user',
            fullName: localStorage.getItem('username') || 'Test',
            username: localStorage.getItem('username'),
            avatar:
                'https://secure.gravatar.com/avatar/b12cd662a157ee749044bdbad0a39af7?s=80&d=identicon',
        });
    }
    
};

export default authProvider;
