
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

const AutomationsShow = () => {
    const record = useRecordContext();
    if (!record) 
        return null;
    return (
        <Card sx={{ width: 1200, margin: 'auto' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <Typography >
                                Description: <Typography sx={{ color: 'orange', fontWeight: '300' }}>{record.description}</Typography>
                            </Typography>
                            <Typography >
                                Expressions: {record.expressions
                                .map((exp:any)=> {
                                    const values = exp.operandValues.map((ov:any)=>ov.value).join(',');
                                        return `${exp.operand.name} ${exp.operatorLeft} [${values}] ${exp.operator}`;
                                    }
                                ).join(' ')
                                }
                            </Typography>
                            <Typography >
                                Commands: {record.commands.map((cmd:any)=>`${cmd.command.element.name}.${cmd.command.action.name}`).join(',')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
export default AutomationsShow;