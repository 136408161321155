
import { Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ReferenceField, Show, TextField, useRecordContext, useTranslate, Labeled, FunctionField } from 'react-admin';

const Spacer = () => <Box m={1}>&nbsp;</Box>;

 const VerificationShow = () => {
    //const record = useRecordContext<IEmployee>();
    const translate = useTranslate();
    //if ( !record ) return null;
    return (
    <Show   >
        <Box  >
                <Card >
                    <CardContent >
                        <Grid container spacing={1} width={{ xs: '100%', xl: '100%' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                
                                <Spacer />
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Labeled source="Signals">
                                            <TextField source="useCase.signals" />
                                        </Labeled>
                                        <Spacer />
                                        <Labeled source="Document">
                                            <TextField source="useCase.documentType" />
                                        </Labeled>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3}>
                                    
                                        <Labeled source="User">
                                            <TextField source="userId" />
                                        </Labeled>
                                    </Grid>

                                    
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Labeled source="Status">
                                            <TextField source="status" />
                                        </Labeled>
                                        <Spacer />
                                        <Labeled source="Check Result">
                                            <TextField source="checkResult" />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Labeled source="Transaction">
                                            <TextField source="transactionId" />
                                        </Labeled>
                                        <Spacer />
                                        <Labeled source="Message">
                                            <TextField source="message" />
                                        </Labeled>
                                    </Grid>
                                    
                                    <Spacer />
                                    <Grid item xs={12} sm={12} md={12}>
                                    
                                    
                                    
                                    <Typography variant="h6" gutterBottom>
                                        Execution log
                                    </Typography>

                                   <Typography> 
                                    <FunctionField label="Duration" render={(record: any) => {
                                        let rows: string[]= [];

                                        if(record.executionLog){
                                             rows = record.executionLog.split(';');
                                        }
                                        
                                        return (
                                            <Table>
                                                
                                                <TableBody>
                                                    {rows.map((item: any) => (
                                                        <TableRow key={item.id} >
                                                            <TableCell>{item}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        );
                                    }} />
                                    </Typography>
                                    
                                </Grid>
                                </Grid>
                                <Spacer />
                            </Grid>
                        </Grid>
                    </CardContent>
        </Card>
        </Box>
    </Show>
    );
    };
    

export default VerificationShow;