
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

const AutomationRequestShow = () => {
    const record = useRecordContext();
    if (!record) 
        return null;
    return (
        <Card sx={{ width: 1200, margin: 'auto' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <Typography >
                                Description: <Typography sx={{ color: 'orange', fontWeight: '300' }}>{record.id}</Typography>
                            </Typography>
                            
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
export default AutomationRequestShow;