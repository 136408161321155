
import { Create, FormTab, number, minValue, NumberInput, ReferenceArrayInput, ReferenceInput, required, SelectArrayInput, SelectInput, TabbedForm, TextInput, BooleanInput, Edit } from "react-admin";

const validateTimestamp = [number(), minValue(13)];

const AntnodeEdit = () => {
    return (
        <Edit>
            <TabbedForm >
                <FormTab label="resources.antnodes.tabs.main" sx={{ maxWidth: '40em' }}>
                    <ReferenceInput
                        label="Blockchain"
                        source='blockchainId'
                        reference="blockchains"
                        sort={{ field: 'name', order: 'ASC' }}
                        alwaysOn
                    >
                        <SelectInput optionText="name" optionValue="id" defaultValue={''} validate={required()} />
                    </ReferenceInput>
                    <TextInput source="name" fullWidth validate={required()} />
                    <TextInput source="nodeUrl" fullWidth validate={required()} />
                    <BooleanInput source="status" />
                    <NumberInput source="height" />
                    <ReferenceInput
                        label="Server"
                        source='serverId'
                        reference="servers"
                        sort={{ field: 'name', order: 'ASC' }}
                        alwaysOn
                    >
                        <SelectInput optionText="name" optionValue="id" defaultValue={''} validate={required()} />
                    </ReferenceInput>
                    <NumberInput source="ping" />
                    <NumberInput source="responseTime"  />
                    <BooleanInput source="inPool" validate={required()} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default AntnodeEdit;