import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin';


const AutomationsListAside = (data:any) => {
    let positions = data.data;
    if(!Array.isArray(positions)) positions=[];
    
    /*const { data:companies } = useGetList<any>('companies', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });*/
    const authors = ['system', 'global-system'];
    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                
                <FilterList label="Building ID" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='buildingId'/>
                </FilterList>

                <FilterList
                label="Status"
                icon={<LocalOfferIcon />}
            >
                <FilterListItem
                    label="Active"
                    value={{ status: true }}
                />
                <FilterListItem
                    label="Inactive"
                    value={{ status: false }}
                />
            </FilterList>
                
                <FilterList label="Authors" icon={<LocalOfferIcon />}>
                    {authors &&
                        authors.map((record: any, index: number) => (
                            <FilterListItem
                            label={record}
                                key={index}
                                value={{ author: record }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default AutomationsListAside;
