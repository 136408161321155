import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin';


const ApartmentsListAside = (data:any) => {
    let positions = data.data;
    if(!Array.isArray(positions)) positions=[];
    

    const authors = ['system', 'global-system'];
    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                
                <FilterList label="Building ID" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='buildingId'/>
                </FilterList>

                <FilterList label="Unit ID" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='unitId'/>
                </FilterList>

                <FilterList label="Address" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='address'/>
                </FilterList>

               
            
                
                
            </CardContent>
        </Card>
    );
};

export default ApartmentsListAside;
