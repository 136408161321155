import { Theme, useMediaQuery } from "@mui/material";
import { BooleanField, CreateButton, Datagrid, DateField, FunctionField, List, Loading, NullableBooleanInput, SearchInput, ShowButton, TextField, TopToolbar, useAuthenticated, useAuthState, useRecordContext, useTranslate, WrapperField } from "react-admin";

import { Pagination } from 'react-admin';
import AutomationRequestShow from "./AutomationRequestShow";
import ColoredResult from "./ColoredResult";
import AutomationRequestsListAside from "./AutomationRequestsListAside";



const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50]} />;

const AutomationRequestsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const translate = useTranslate();
    //const record = useRecordContext();
    const positions: any = [];
    return (
        <List  aside={<AutomationRequestsListAside data={positions}/>} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }} pagination={<PostPagination />}>
            <Datagrid rowClick="expand" expand={<AutomationRequestShow />}>
                <TextField source="id" />
                <TextField source="companyId" />
                <TextField source="corellationId" />
                <TextField source="createdAt" />
                <TextField source="updatedAt" />
                <FunctionField label="Duration" render={(record: any) => {
                    const created = new Date(record.createdAt).getTime();
                    const updated = new Date(record.updatedAt).getTime();
                    const duration = (updated-created)/1000;
                    return `${duration} s`;
                }} />
                <ColoredResult sortable={false} label="Result" source="result"/>
                <TextField source="info" />
                
            </Datagrid>
        </List>
    );
}
const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}></TopToolbar>
);

export default AutomationRequestsList;