import { Theme, useMediaQuery } from "@mui/material";
import { BooleanField, CreateButton, Datagrid, DateField, FunctionField, List, Loading, NullableBooleanInput, SearchInput, ShowButton, TextField, TopToolbar, useAuthenticated, useAuthState, useRecordContext, useTranslate, WrapperField } from "react-admin";

import { Pagination } from 'react-admin';
import ApartmentsListAside from "./ApartmentsListAside";



const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50]} />;
const ApartmentsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const translate = useTranslate();
    //const record = useRecordContext();
    const positions: any = [];
    return (
        <List  aside={<ApartmentsListAside data={positions}/>} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }} pagination={<PostPagination />}>
            <Datagrid rowClick="expand" >
                <TextField source="id" />
                <TextField source="buildingId" />
                <TextField source="unitId" />
                <TextField source="externalId" />
                <TextField source="createdAt" />
                <TextField source="updatedAt" />
                <TextField source="externalUpdatedAt" />
                <TextField source="address" />
                
            </Datagrid>
        </List>
    );
}
const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}></TopToolbar>
);

export default ApartmentsList;