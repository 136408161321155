import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, TextField, FunctionField } from 'react-admin';


const ColoredLevel = (props: NumberFieldProps) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
        return null;
    }
                    
   /*  const left = Number.isNaN(+(record?.height ?? undefined)) ? 'N/A' : record?.height;
    const right = Number.isNaN(+(record?.blockchain?.height ?? undefined)) ? 'N/A' : record?.blockchain?.height;
 */
    const condition = ( record.level == 'error' );
    const value = `${record.level}`;
    return condition ? (
        <FunctionField sx={{ color: 'red' }}  render={(record: any) => value} />
    ) : (
        <FunctionField render={(record: any) => value} />
    );
};

ColoredLevel.defaultProps = NumberField.defaultProps;

export default ColoredLevel;
