import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin';


const VerificationsListAside = (data:any) => {
    let positions = data.data;
    if(!Array.isArray(positions)) positions=[];
    

   
    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                
                <FilterList label="ID" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='id'/>
                </FilterList>

                <FilterList label="Transaction ID" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='transactionId'/>
                </FilterList>

                <FilterList label="UserID" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='userId'/>
                </FilterList>

                <FilterList label="Message" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='message'/>
                </FilterList>

                <FilterList label="Check result" icon={<LocalOfferIcon />}>
                    <FilterListItem
                        label="True"
                        value={{ checkResult: true }}
                    />
                    <FilterListItem
                        label="False"
                        value={{ checkResult: false }}
                    />                                                                                      
                </FilterList>

                <FilterList label="Status" icon={<LocalOfferIcon />}>
                    <FilterListItem
                        label="Opened"
                        value={{ status: 'opened' }}
                    />
                    <FilterListItem
                        label="Finished"
                        value={{ status: 'finished' }}
                    />    
                    <FilterListItem
                        label="Aborted"
                        value={{ status: 'aborted' }}
                    />                                                                                    
                </FilterList>



               
            
                
                
            </CardContent>
        </Card>
    );
};

export default VerificationsListAside;
