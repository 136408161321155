
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

const LoggingAppShow = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Card sx={{ width: 600, margin: 'auto' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography  gutterBottom>
                        {JSON.stringify(record.payload, null, 2)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
export default LoggingAppShow;