import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            
            <div style={{color:'#3498db'}}> <strong>MICROSERVICES </strong>DASHBOARD</div>
        </div>
        
        
    );
};

export default Logo;
