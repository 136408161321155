import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { BooleanField, CreateButton, Datagrid, EditButton, FunctionField, List, Loading, TextField, TopToolbar, useTranslate } from "react-admin";
import AntnodeListAside from "./AntnodeListAside";
import ColoredBacklogFieldAnt from "./ColoredBacklogFieldAnt";
import ColoredHealthField from "./ColoredHealthField";
import ColoredRamFieldAnt from "./ColoredRamFieldAnt";

const url = `${process.env.REACT_APP_API_URL || 'https://logs.walk.in'}`;

const request = new Request(`${url}/blockchains`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
});

const AntnodeList = () => {

    const translate = useTranslate();
    const [blockchains, setBlockchains] = useState('');
    useEffect(() => {
        (async () => {
            const response = await fetch(request);
            const res = await response.json();
            setBlockchains(res);
        })();
    }, [setBlockchains]);
    if (!blockchains) return <Loading />
    return (
        <List 
        

        actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField label="Node" source="name" />
                <FunctionField label="Provider" render={(record: any) => `${record?.server?.provider?.name} - ${record?.server?.name}`} />
                
                <ColoredBacklogFieldAnt sortable={false} label="Block" source="height"/>
                <FunctionField label="Ping" render={(record: any) => {
                    if(Number.isNaN(+(record?.ping ?? undefined))) return 'N/A';
                    return `${+record?.ping / 1000} ms`;
                }} />
                <FunctionField label="Response" render={(record: any) => {
                    if(Number.isNaN(+(record?.responseTime ?? undefined))) return 'N/A';
                    return `${+record?.responseTime} ms`;
                }} />
                <FunctionField label="Memory Home" render={(record: any) => {
                    const left = Number.isNaN(+(record?.server?.homeMemoryUsage ?? undefined)) ? 'N/A' : (record?.server?.homeMemoryUsage/1024/1024/1024).toFixed(2);
                    const right = Number.isNaN(+(record?.server?.homeMemoryTotal ?? undefined)) ? 'N/A': (record?.server?.homeMemoryTotal/1024/1024/1024).toFixed(2);
                    return `${left} / ${right} GB`;
                }} />
                <FunctionField label="Memory Root" render={(record: any) => {
                    const left = Number.isNaN(+(record?.server?.rootMemoryUsage ?? undefined)) ? 'N/A' : (record?.server?.rootMemoryUsage/1024/1024/1024).toFixed(2);
                    const right = Number.isNaN(+(record?.server?.rootMemoryTotal ?? undefined)) ? 'N/A' : (record?.server?.rootMemoryTotal/1024/1024/1024).toFixed(2);
                    return `${left} / ${right} GB`;
                }} />
                
                <ColoredRamFieldAnt sortable={false} label="RAM" source="inPool"/>
                <BooleanField sortable={false} source="inPool"/>
                <ColoredHealthField label="Status" source="ping" />
            </Datagrid>
        </List>
    );
}

const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        <CreateButton />
        <EditButton />
    </TopToolbar>
);

export default AntnodeList;