import * as React from 'react';
import { Admin, CustomRoutes, fetchUtils, Resource, useAuthenticated, useAuthState, useNotify, usePermissions, Authenticated, combineDataProviders } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';
import jsonServerProvider from 'ra-data-json-server';

import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { darkTheme, lightTheme } from './layout/themes';

import visitors from './visitors';
import orders from './orders';
import products from './products';
import invoices from './invoices';
import categories from './categories';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';
import employees from './employees';
import simpleRestProvider from 'ra-data-simple-rest';
import logging from './logging';
import loggingapp from './loggingapp';
import loggingevents from './loggingevents';
import automations from './automations';
import automation_requests from './automation_requests';
import apartments from './apartments';
import verifications from './verifications';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }
    // Always fallback on english
    return englishMessages;
}, 'en');

const httpClient = (url: any, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    options.credentials = 'include'

    return fetchUtils.fetchJson(url, options)
}

const msIntellicheckApiKey = process.env.REACT_APP_VERIFICATIONS_API_KEY || 'api key not setup';
const httpClientTwo = (url: any, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    options.headers = new Headers({ Accept: 'application/json', Authorization: `ApiKey ${msIntellicheckApiKey}` });
    options.credentials = 'include'

    return fetchUtils.fetchJson(url, options)
}

const logProvider = simpleRestProvider(`${process.env.REACT_APP_API_URL  }`, httpClient);
const automationsProvider = simpleRestProvider(`${process.env.REACT_APP_AUTOMATIONS_API_URL}`, httpClient); 
const verificationsUrl = process.env.REACT_APP_VERIFICATIONS_API_URL || 'not setup url';
const verificationsProvider = simpleRestProvider(verificationsUrl, httpClientTwo); 

/*const crmProvider = {
    ...dataProvider,
    banUser: (userId:number) => {
        return fetch(`/api/user/${userId}/ban`, { method: 'POST' })
            .then(response => response.json());
    },
    update: async (resource: any, params: any) => {
        if (resource === 'users' && params?.data?.password) {
            console.log(params?.data?.password)
            try{
                const result: any = await new Promise((resolve, reject)=>{
                    httpClient(`${process.env.REACT_APP_API_URL || 'https://logs.walk.in'}/users/${params?.data?.id}/password`, {
                        method: 'PATCH',
                        Headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        body: JSON.stringify({old_password: params?.data?.old_password, password:params?.data?.password}),
                    }).then(result => {
                        resolve(JSON.parse(result.body))}
                    ).catch(e=>{
                        reject(e);
                    });
                }); 
                
            } catch(e:any){
                console.error('password change error')
                throw new Error(e.message);
            }
            
            return dataProvider.update(resource, params);
        }
        if (resource !== 'employees' || !params.data.file) {
            return dataProvider.update(resource, params);
        }
        const meta = {employee: params.data.id, category:"" }
        let formData = new FormData();

        formData.append('paramOne', params.data.paramOne);
        //formData.append('body', JSON.stringify(params.previousData));
        formData.append('file', params.data.file.rawFile);
        if(params.data.file.category){
            formData.append('category', params.data.file.category);
            meta.category = params.data.file.category;
        }
        formData.append('meta', JSON.stringify(meta));
        
        if(params.data.file.rawFile){
            try{
                const file: any = await new Promise((resolve, reject)=>{
                    httpClient(`${process.env.REACT_APP_API_URL || 'https://logs.walk.in'}/files`, {
                        method: 'POST',
                        body: formData,
                    }).then(result => {
                        resolve(JSON.parse(result.body))}
                    ).catch(e=>{
                        reject(e);
                    });
                }); 
                if(file){
                    params.data.filesIds.push(file.id)
                }
            } catch(e){
                console.error('file upload error')
            }
        }
        
        
        return dataProvider.update(resource, params);
        
    }

}*/
//dataProvider = dataProviderFactory(    process.env.REACT_APP_DATA_PROVIDER || '');

const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'companies':
        case 'logging':
        case 'loggingapp':
        case 'loggingevents':
            return logProvider;
        case 'automations':
        case 'automation_requests':
        case 'apartments':
            return automationsProvider;
        case 'verifications':
            return verificationsProvider;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

const App = () => {
    const { permissions } = usePermissions();
    
    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={darkTheme}
            requireAuth
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
                <Route path="/segments" element={<Segments />} />
            </CustomRoutes>

            
            <Resource name="logging"  {...logging} />
            <Resource name="loggingapp"  {...loggingapp} />
            <Resource name="loggingevents"  {...loggingevents} />
            <Resource name="automations"  {...automations} />
            <Resource name="automation_requests"  {...automation_requests} />
            <Resource name="apartments"  {...apartments} />
            <Resource name="verifications"  {...verifications} />
            
        </Admin>
    );
};

export default App;
