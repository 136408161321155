import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, TextField, FunctionField } from 'react-admin';


const ColoredBacklogFieldAnt = (props: NumberFieldProps) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
        return null;
    }
                    
    const left = Number.isNaN(+(record?.height ?? undefined)) ? 'N/A' : record?.height;
    const right = Number.isNaN(+(record?.blockchain?.height ?? undefined)) ? 'N/A' : record?.blockchain?.height;

    const condition = ( (right - left) >=2 );
    const value = `${left} / ${right}`;
    return condition ? (
        <FunctionField sx={{ color: 'red' }}  render={(record: any) => value} />
    ) : (
        <FunctionField render={(record: any) => value} />
    );
};

ColoredBacklogFieldAnt.defaultProps = NumberField.defaultProps;

export default ColoredBacklogFieldAnt;
