import { Theme, useMediaQuery } from "@mui/material";
import { CreateButton, Datagrid, DateField, DateInput, DateTimeInput, FunctionField, List, Loading, NullableBooleanInput, SearchInput, ShowButton, TextField, TopToolbar, useAuthenticated, useAuthState, useTranslate, WrapperField } from "react-admin";

import { Pagination } from 'react-admin';
import LoggingEventsListAside from "./LoggingEventsListAside";
import LoggingEventsShow from "./LoggingShow";

const url = `${process.env.REACT_APP_API_URL || 'https://logs.walk.in'}`;
const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} />;

const listFilters = [
    <DateInput source="date_gte" label="Date from" alwaysOn />,
    <DateInput source="date_lte" label="Date to" alwaysOn />,
];

const LoggingAppList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const translate = useTranslate();

    const positions: any = [];
    return (
        <List  
            aside={<LoggingEventsListAside data={positions}/>} 
            filters={listFilters}
            actions={<ListActions />} 
            sort={{ field: 'id', order: 'DESC' }} 
            pagination={<PostPagination />}
            >
            <Datagrid rowClick="expand" expand={<LoggingEventsShow />}>
                <TextField source="id" />
                <FunctionField label="Timestamp" render={(record: any) => { 
                            try{
                                const date2 = new Date(parseInt(record.date));
                                return `${date2.toString().split('GMT')[0]}`;
                            } catch (e){
                                return 'error';
                            }
                        } 
                    } 
                />
                <FunctionField label="User Id" render={(record: any) => record?.domainUser?.userId} />
                <FunctionField label="User Name" render={(record: any) => { return `${record?.domainUser?.first} ${record?.domainUser?.last}`} }/>
                <FunctionField label="User Type" render={(record: any) => record?.domainUser?.roleTitle} />
                <FunctionField label="Company" render={(record: any) => record?.domainCompany?.name} />
                <TextField label="Action"  source="action" />
                <FunctionField label="Entity ID" render={(record: any) => record?.domainEntity?.externalId} />
                <FunctionField label="Entity Name" render={(record: any) => record?.domainEntity?.name} />
                <FunctionField label="Entity" render={(record: any) => record?.domainEntity?.entityType} />
                <TextField label="Details" source="message" />
            </Datagrid>
        </List>
    );
}
const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    </TopToolbar>
);

export default LoggingAppList;