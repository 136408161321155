import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { DateInput, Filter, FilterList, FilterListItem, FilterLiveSearch, TextInput, useGetList } from 'react-admin';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import inflection from 'inflection';

const LoggingEventsListAside = (data:any) => {
    let positions = data.data;
    if(!Array.isArray(positions)) positions=[];
    
    const { data:companies } = useGetList<any>('companies', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />
                
                <FilterList label="resources.loggingevents.filters.actionType" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='action'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.entityType" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='entityType'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.entityId" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='entityExternalId'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.entityName" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='entityName'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.userName" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='userName'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.userId" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='userId'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.userType" icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='user_type'  />
                </FilterList>
                <FilterList label="resources.loggingevents.filters.companyName"  icon={<MonetizationOnIcon />} >
                    <FilterLiveSearch source='companyName'/>
                </FilterList>
                <FilterList label="resources.loggingevents.filters.company" icon={<MonetizationOnIcon />}>
                    {companies &&
                        companies.map((record: any) => (
                            <FilterListItem
                            label={record.name}
                                key={record.id}
                                value={{ companyId: record.id }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default LoggingEventsListAside;
