import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupsIcon from '@mui/icons-material/Groups';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import Antnodes from '../exchange/antnodes';
import SubMenu from './SubMenu';
import Resources from '../rbac/resources';

type MenuName = 'menuProperties' | 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuEmployees' | 'menuFiles' | 'menuProjects' 
| 'menuSkills' | 'menuSystem' | 'menuPosition' | 'menuAutomations' | 'menuVerifications' | 'menuRbac';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuEmployees: true,
        menuFiles: true,
        menuProjects: true,
        menuSkills: true,
        menuSystem: false,
        menuPosition: true,
        menuProperties: true,
        menuAutomations: true,
        menuVerifications: true,
        menuRbac: false
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />


            {/* <MenuItemLink
                to="/users"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<visitors.icon />}
                dense={dense}
            /> */}
            
            {/*
            <MenuItemLink
                to="/projects"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.projects.name`, {
                    smart_count: 2,
                })}
                leftIcon={<Projects.icon />}
                dense={dense}
            />
            */}

            
            <MenuItemLink
                to="/logging"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.logging.name`, {
                    smart_count: 2,
                })}
                leftIcon={<Antnodes.icon />}
                dense={dense}
            />

            <MenuItemLink
                to="/loggingapp"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.loggingapp.name`, {
                    smart_count: 2,
                })}
                leftIcon={<Antnodes.icon />}
                dense={dense}
            />

            <MenuItemLink
                to="/loggingevents"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.loggingevents.name`, {
                    smart_count: 2,
                })}
                leftIcon={<Antnodes.icon />}
                dense={dense}
            />

            <SubMenu
                    handleToggle={() => handleToggle('menuVerifications')}
                    isOpen={state.menuAutomations}
                    name="pos.menu.verifications"
                    icon={<GroupsIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/verifications"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.verifications.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<Resources.icon/>}
                        dense={dense}
                    />
            </SubMenu>

            <SubMenu
                    handleToggle={() => handleToggle('menuAutomations')}
                    isOpen={state.menuAutomations}
                    name="pos.menu.automations"
                    icon={<GroupsIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/automations"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.automations.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<Resources.icon/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/automation_requests"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.automation_requests.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<Resources.icon/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/apartments"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.apartments.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<Resources.icon/>}
                        dense={dense}
                    />
                    
                </SubMenu>
            
          
        </Box>
    );
};

export default Menu;
