import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterLiveSearch, useGetList } from 'react-admin';


const AutomationRequestsListAside = (data:any) => {
    let positions = data.data;
    if(!Array.isArray(positions)) positions=[];
    
    const { data:companies } = useGetList<any>('companies', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />
                <FilterList label="Corellation Id" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='corellationId'/>
                </FilterList>

                
                
            </CardContent>
        </Card>
    );
};

export default AutomationRequestsListAside;
