import { Theme, useMediaQuery } from "@mui/material";
import { BooleanField, CreateButton, Datagrid, DateField, FunctionField, List, Loading, NullableBooleanInput, SearchInput, ShowButton, TextField, TopToolbar, useAuthenticated, useAuthState, useRecordContext, useTranslate, WrapperField } from "react-admin";

import { Pagination } from 'react-admin';
import AutomationsShow from "./AutomationShow";
import AutomationsListAside from "./AutomationsListAside";


const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50]} />;
const AutomationsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const translate = useTranslate();
    //const record = useRecordContext();
    const positions: any = [];
    return (
        <List  aside={<AutomationsListAside data={positions}/>} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }} pagination={<PostPagination />}>
            <Datagrid rowClick="expand" expand={<AutomationsShow />}>
                <TextField source="id" />
                <TextField source="companyId" />
                <BooleanField source="status" />
                <TextField source="author" />
                <TextField source="modifiedBy" />
                <TextField source="createdAt" />
                <TextField source="updatedAt" />
                <FunctionField label="Expressions" render={(record: any) => record?.expressions?.length} />
                <FunctionField label="Commands" render={(record: any) => record?.commands?.length} />
                <FunctionField label="?" render={(record: any) => record?.rules[0]?.values[0]?.value} />
                <TextField source="name" />
            </Datagrid>
        </List>
    );
}
const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}></TopToolbar>
);

export default AutomationsList;