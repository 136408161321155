import { Theme, useMediaQuery } from "@mui/material";
import { BooleanField, CreateButton, Datagrid, DateField, FunctionField, List, Loading, NullableBooleanInput, SearchInput, ShowButton, TextField, TopToolbar, useAuthenticated, useAuthState, useRecordContext, useTranslate, WrapperField } from "react-admin";

import { Pagination } from 'react-admin';
import VerificationsListAside from "./VerificationsListAside";
import VerificationShow from "./VerificationShow";



const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50]} />;

const VerificationsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const translate = useTranslate();
    //const record = useRecordContext();
    const positions: any = [];
    return (
        <List  aside={<VerificationsListAside data={positions}/>} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }} pagination={<PostPagination />}>
            <Datagrid rowClick="show" /*expand={<VerificationShow />}*/>
                <TextField source="id" />
                <FunctionField label="Document" render={(record: any) => { return `${record?.useCase?.documentType}`} }/>
                <TextField source="checkResult" />
                <TextField source="transactionId" />
                <TextField source="useCaseId" />
                <TextField source="userId" />
                <TextField source="status" />
                <TextField source="message" />
                <TextField source="createdAt" />
                <TextField source="updatedAt" />
                
                
            </Datagrid>
        </List>
    );
}
const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}></TopToolbar>
);

export default VerificationsList;