import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, TextField, FunctionField } from 'react-admin';

const ColoredHealthField = (props: NumberFieldProps) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
        return null;
    }

    const blocks = Math.abs(parseInt(record?.height) - parseInt(record?.blockchain?.height));
    const ram = ( (parseInt(record?.server?.ramUsage) / parseInt(record?.server?.ramTotal)) *100) ;
    const memoryHome =  ( parseInt( (record?.server?.homeMemoryTotal/1024).toFixed(2) ) - parseInt( (record?.server?.homeMemoryUsage/1024).toFixed(2)) ) ;
    const memoryRoot =  ( parseInt(record?.server?.rootMemoryTotal) - parseInt(record?.server?.rootMeemoryUsage) ) ;
    const ping = record?.ping;
    
    const memoryHomeGb = Math.ceil(memoryHome/1024/1024);
   

    const condition = (!(blocks <=2) || !(ram <= 70 ) || (memoryHomeGb <= 100) || !ping);
    return condition ? (
        <FunctionField title={`blocks: ${blocks}, ram: ${Math.ceil(ram)}%, memoryHome: ${memoryHomeGb}`} sx={{ color: 'red' }}  render={(record: any) => 'UnHealth' } />
    ) : (
        <FunctionField title={`blocks: ${blocks}, ram: ${Math.ceil(ram)}%, memoryHome: ${memoryHomeGb}`}  sx={{ color: 'green' }}  render={(record: any) => 'Health' } />
    );
};

ColoredHealthField.defaultProps = NumberField.defaultProps;

export default ColoredHealthField;
