import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';


const Aside = (data:any) => {
    let positions = data.data;
    if(!Array.isArray(positions)) positions=[];

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />
                <FilterList label="resources.loggingapp.filters.corellationId" icon={<LocalOfferIcon />} >
                    <FilterLiveSearch source='corellationId'/>
                </FilterList>
                <FilterList label="resources.loggingapp.filters.level" icon={<LocalOfferIcon />} >
                    {[{id:"error", name:'error'}, {id:"warn", name:'warn'}, {id:"info", name:'info'}, {id:"debug", name:'debug'}].map(segment => (
                        <FilterListItem
                            label={segment.name}
                            key={segment.id}
                            value={{ level: segment.id }}
                        />
                    ))}
                </FilterList>

                <FilterList label="resources.loggingapp.filters.env" icon={<LocalOfferIcon />} >
                    {[{id:"development", name:'development'}, {id:"production", name:'production'}, {id:"local", name:'local'}, {id:"staging", name:'staging'}, {id:"test", name:'test'}].map(segment => (
                        <FilterListItem
                            label={segment.name}
                            key={segment.id}
                            value={{ env: segment.id }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;
