import * as React from 'react';
import { Box, Theme, useMediaQuery } from "@mui/material";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    SearchInput,
    TopToolbar,
    CreateButton,
    EditButton,
} from 'react-admin';
import RbacResourceShow from './RbacResourceShow';
import RbacResourceListAside from './RbacResourceListAside';



const filters = [
    <SearchInput source="q" alwaysOn />,
];



const RbacResourceList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            aside={<RbacResourceListAside data={[]}/>} 
            
            filters={isSmall ? filters : undefined}
            perPage={25}
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid
                rowClick="expand"
                expand={<RbacResourceShow />}
                sx={{
                    '& .column-customer_id': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-total_ex_taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-delivery_fees': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="group" />
                <EditButton />
                
            </Datagrid>
        </List>
    );
};



export default RbacResourceList;
