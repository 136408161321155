
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

function extractDetails(record:any){
    try{
        const splitted = record.details.split('{"description":')[0];
        const parsed = JSON.parse(record.details.substring(splitted.length));
        const json = JSON.stringify(parsed, null, 2);
        return `${json}`;
    } catch(e){

    }

    return record.details;
}

const LoggingEventsShow = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Card sx={{ width: 600, margin: 'auto' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <Typography >
                                Domain: <Typography sx={{ color: 'orange', fontWeight: '300' }}>{record.domain}</Typography>
                            </Typography>
                        
                            <Typography >
                                Request Id: <Typography sx={{ color: 'orange', fontWeight: '300' }}>{record.requestId}</Typography>
                            </Typography>
                            <Typography >
                                Initiator: <Typography sx={{ color: 'orange', fontWeight: '300' }}>{record.initiator}</Typography>
                            </Typography>
                            <Typography>
                                Delta: <Typography sx={{ color: 'orange' , fontWeight: '300'}}>{JSON.stringify(record.delta, null, 2)}</Typography>
                            </Typography>
                            <pre>
                                
                            </pre>
                        </div>
                        
                        
                        
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
export default LoggingEventsShow;