import * as React from 'react';
import { useRecordContext, NumberField, NumberFieldProps, TextField, FunctionField } from 'react-admin';

const ColoredResult = (props: NumberFieldProps) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
        return null;
    }

    const condition = ( record.result == 'ABORTED' );
    const value = `${record.result}`;
    return condition ? (
        <FunctionField sx={{ color: 'orangered' }}  render={(record: any) => value} />
    ) : (record.result == 'PENDING') ? <FunctionField sx={{ color: 'moccasin' }}  render={(record: any) => value} /> : ( 
        <FunctionField sx={{ color: 'olive' }}  render={(record: any) => value} />
    );
};

ColoredResult.defaultProps = TextField.defaultProps;

export default ColoredResult;
